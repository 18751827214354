// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-subscribed-js": () => import("./../../../src/pages/subscribed.js" /* webpackChunkName: "component---src-pages-subscribed-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-industries-page-js": () => import("./../../../src/templates/industries-page.js" /* webpackChunkName: "component---src-templates-industries-page-js" */),
  "component---src-templates-markets-article-js": () => import("./../../../src/templates/markets-article.js" /* webpackChunkName: "component---src-templates-markets-article-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/product-category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-project-article-js": () => import("./../../../src/templates/project-article.js" /* webpackChunkName: "component---src-templates-project-article-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/team-page.js" /* webpackChunkName: "component---src-templates-team-page-js" */),
  "component---src-templates-terms-and-conditions-page-js": () => import("./../../../src/templates/terms-and-conditions-page.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-page-js" */),
  "component---src-templates-video-page-js": () => import("./../../../src/templates/video-page.js" /* webpackChunkName: "component---src-templates-video-page-js" */)
}

